import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ChevronDownIcon from '../SVGIcons/ChevronDownIcon';
import ChevronUpIcon from '../SVGIcons/ChevronUpIcon';

export default class AccordionHeader extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={`dsg-react-accordion-header ${this.props.classes}`}
        onClick={this.props.onToggleAccordion}
        aria-expanded={this.props.expanded}>
        <div>{this.props.title}</div>
        {this.props.expanded ? (
          <ChevronUpIcon classes={['dsg-react-accordion-svg']} />
        ) : (
          <ChevronDownIcon classes={['dsg-react-accordion-svg']} />
        )}
      </div>
    );
  }
}

AccordionHeader.propTypes = {
  onToggleAccordion: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
};
