import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AccordionHeader from './AccordionHeader';
import AccordionBody from './AccordionBody';

export default class Accordion extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggleAccordion = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const accordionExtraClasses = this.props.classes.join(' ').trim();
    const accordionHeaderExtraClasses = this.props.headerClasses.join(' ').trim();
    const accordionBodyExtraClasses = this.props.bodyClasses.join(' ').trim();

    return (
      <div className={`dsg-react-accordion ${accordionExtraClasses}`}>
        <AccordionHeader
          onToggleAccordion={this.toggleAccordion}
          expanded={this.state.expanded}
          title={this.props.title}
          classes={accordionHeaderExtraClasses}
        />
        <AccordionBody expanded={this.state.expanded} classes={accordionBodyExtraClasses}>
          {this.props.children}
        </AccordionBody>
      </div>
    );
  }
}

Accordion.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  headerClasses: PropTypes.arrayOf(PropTypes.string),
  bodyClasses: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.any.isRequired,
  expanded: PropTypes.bool,
};

Accordion.defaultProps = {
  children: null,
  classes: [],
  headerClasses: [],
  bodyClasses: [],
};
