import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class ChevronUpIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="8"
        height="5"
        viewBox="0 0 8 5"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.3225 4.98917L4 2.3175L6.6775 4.98917L7.5 4.16667L4 0.666668L0.5 4.16667L1.3225 4.98917Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

ChevronUpIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

ChevronUpIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
