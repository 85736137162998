import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class AccordionBody extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={`dsg-react-accordion-body ${this.props.expanded ? 'show' : 'hide'} ${
          this.props.classes
        }`}>
        {this.props.children}
      </div>
    );
  }
}

AccordionBody.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

AccordionBody.defaultProps = {
  children: null,
};
