import React, { useState } from 'react';

import Accordion from '../common/Accordion/Accordion';
import { useSelector } from 'react-redux';
import { categoryAttrVoDataSelector } from '../../reduxStore/selectors';

const SEOData = () => {
  const categoryAttrVoData = useSelector(categoryAttrVoDataSelector);

  const [seoHeading] = useState(
    categoryAttrVoData && categoryAttrVoData.SEO_HEADLINE
      ? categoryAttrVoData.SEO_HEADLINE.value.replace(/\ufffd/g, "'")
      : null,
  );
  const [featuredLinksValueObj] = useState(
    categoryAttrVoData &&
      categoryAttrVoData.SEO_FEATURED_LINKS &&
      categoryAttrVoData.SEO_FEATURED_LINKS.value
      ? JSON.parse(categoryAttrVoData.SEO_FEATURED_LINKS.value)
      : null,
  );

  const categoryAttrVoDataMatchPattern = /\bSEO_TAB\d_HEADING/;
  const categoryAttrVoDataMatchPatternTwo = /\bSEO_TAB\d_CONTENT/;

  const generateSeoContentTabs = () => {
    /*
     * Loops over the categoryAttrVoData object looking for keys that start with SEO_TAB and end with HEADING
     * If found it renders an accordion if a matching CONTENT key exists
     */
    if (
      categoryAttrVoData &&
      Object.keys(categoryAttrVoData).some((key) => {
        return (
          categoryAttrVoDataMatchPattern.test(key) || categoryAttrVoDataMatchPatternTwo.test(key)
        );
      })
    ) {
      return Object.keys(categoryAttrVoData).map((key) => {
        if (key.search(categoryAttrVoDataMatchPattern) == 0) {
          if (categoryAttrVoData[key]?.value) {
            const contentKey = key.replace('HEADING', 'CONTENT');
            if (categoryAttrVoData[contentKey]?.value) {
              return (
                <Accordion
                  key={key}
                  title={<p className="expansion-title">{categoryAttrVoData[key]?.value}</p>}
                  classes={['expansion-panel', 'search-dex-content']}
                  headerClasses={['expansion-summary']}
                  bodyClasses={['expansion-details']}>
                  <div
                    dangerouslySetInnerHTML={{ __html: categoryAttrVoData[contentKey]?.value }}
                  />
                </Accordion>
              );
            }
          }
        }
      });
    }

    return null;
  };

  return (
    <>
      <div className="searchdex-body">
        {// Displays SEO Heading only when there are SEO Content tabs or Featured Links for the family page
        (categoryAttrVoData &&
          Object.keys(categoryAttrVoData).some((key) => {
            return (
              categoryAttrVoDataMatchPattern.test(key) ||
              categoryAttrVoDataMatchPatternTwo.test(key)
            );
          })) ||
        featuredLinksValueObj ? (
          <h2>{seoHeading}</h2>
        ) : null}
        {generateSeoContentTabs()}
        {featuredLinksValueObj ? (
          <Accordion
            title={<p className="expansion-title">Featured Categories</p>}
            classes={['expansion-panel', 'searchdex-featured-categories']}
            headerClasses={['expansion-summary']}
            bodyClasses={['expansion-details']}>
            <ul>
              {Object.keys(featuredLinksValueObj).map((link, key) => {
                return (
                  <li key={key}>
                    <a href={featuredLinksValueObj[link]}>{link}</a>
                  </li>
                );
              })}
            </ul>
          </Accordion>
        ) : null}
      </div>
    </>
  );
};

export default SEOData;
