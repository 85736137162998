import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class ChevronDownIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.825 0.590088L5 4.40675L1.175 0.590088L0 1.76509L5 6.76509L10 1.76509L8.825 0.590088Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

ChevronDownIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

ChevronDownIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
